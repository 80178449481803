.card-dev {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 436px;
  

  text-align: center;
  font-size: 18px;
  background: #fff;

  .card-dev-content {
      /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  /* Configuração webkit referencia da documentação - Avaliar necessidade */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 90%;
  width: 90%;
  margin: 0;

  color: white;

  }



  transition: 0.3s all ease;

  &.cyan {
    background: kt-get($kt-state-colors, homeCyan, base);
  }

  &.green {
    background: kt-get($kt-state-colors, homeGreen, base);
  }

  &.purple {
    background: kt-get($kt-state-colors, homePurple, base);

    p {
      max-width: 82%;
    }
  }

  div {

    img {
      min-height: 36px;
      height: 7vw;
      width: auto;
      margin-bottom: calc(1px + 2vw);
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: 28px;
      
      h3 {
        white-space: nowrap;
        max-width: 100%;
        font-size: clamp(1em, 1.5vw, 1.8em);
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: white;
        text-align: center;
      }
    }

    p {
      width: 94%;
      font-size: clamp(0.8em, 1.5vw, 1.16em);
      margin-bottom: 0;
      font-weight: 400;
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    div {
      .title {
        height: unset;
        margin-bottom: 5%;

        h3 {
          max-width: 100%;
        }
      }

      p {
        display: inline-block;
        width: 94%;
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
        color: white;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    div {

      img {
        height: 107.42px;
        margin-bottom: 29.8px;
      }
    }
  }
}
