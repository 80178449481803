#dna-verzel-container {
  background-image: linear-gradient(
    kt-get($kt-state-colors, light, inverse) 43.2%,
    kt-get($kt-state-colors, light, base) 40%
  );
  padding: calc(12.48px + 3.2vw) 4vw;

  .top-container {

    .top-container-textos {
      display: flex;
      flex-direction: column;
      position: relative;

      .texto-apresentacao {
        justify-content: center;
        text-align: center;
        position: relative;

        p {
          font-family: "Montserrat", sans-serif;
          font-size: clamp(1rem, 2.5vw, 1.85rem);
          font-weight: 400;
          letter-spacing: -0.02em;
          line-height: 180%;
        }
        span {
          color: kt-get($kt-state-colors, homePurple, base);
          font-weight: 700;
        }
      }

      .frases {
        position: absolute;
        text-align: right;
        top: 100%;
        left: 19%;

        @media screen and (min-width: 950px) and (max-width: 1023px) {
          left: 27%;
        }

        p {
            float: right;
            padding: 1.1% 1.5%;
            margin-bottom: 2.5%;
            font-family: "Montserrat", sans-serif;
            font-size: clamp(0.9em, 2.3vw, 1.75em);
            font-weight: 500;
            letter-spacing: -0.01em;
            white-space: nowrap;
            background-color: kt-get($kt-state-colors, colorPortilet, base);
            color: kt-get($kt-state-colors, light, base);
        }
      }
    }

    .top-container-img {
      display: flex;
      flex-direction: column;
      position: relative;

      .smartphone {
        position: relative;
        width: 65.5%;
        max-width: 450px;
        img {
          width: 100%;
        }
      }
    }
  }

  .dna-verzel {
    h2 {
      margin: 2.2vw auto 4.3vw;
      font-family: "Montserrat", sans-serif;
      font-size: clamp(1.5rem, 4vw, 3.5rem);
      text-align: center;

    }

    p {
      width: 75.18%;
      max-width: 400px;
      margin: 0 auto calc(12.48px + 3.2vw);
      font-family: "Montserrat", sans-serif;
      font-size: clamp(1rem, 2.5vw, 1.85rem);
      font-weight: 400;
      line-height: 180%;
      text-align: center;
      letter-spacing: -0.01em;

      @media screen and (min-width: 371px) and (max-width: 532px) {
        max-width: 255px;
      }

      @media screen and (min-width: 533px) and (max-width: 593px) {
        max-width: 284px;
      }

      @media screen and (min-width: 594px) and (max-width: 603px) {
        max-width: 317px;
      }

      @media screen and (min-width: 836px) {
        max-width: 473px;
      }

      span {
        color: kt-get($kt-state-colors, homePurple, base);
        font-weight: bold;
      }
    }
  }

  .cards-dna-container {
    padding: 0 3px;
    .cards-dna {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1px;
    }
  }

  .botao-container{
    .botao {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 384px;
      max-height: 64px;
      height: calc(35px + 2vw);
      font-family: "Montserrat", sans-serif;
      font-size: clamp(1rem, 2vw, 1.85rem);
      font-weight: 600;
      line-height: 150%;
      color: kt-get($kt-state-colors, light, base);
      border: none;
      background: kt-get($kt-state-colors, colorPortilet, base);
      transition: 0.2s background-color ease;


      &:hover {
        background: kt-get($kt-state-colors, homeButtonGreen, base);
        color: unset;
      }
    }
  }

  @media screen and (max-width: 359px) {
    .top-container .top-container-textos {
        .texto-apresentacao {
          p {
            font-size: 9.2px;
          }
        }
        .frases {
          font-size: 9.2px;
        }
    }
  }

  @media screen and (min-width: 360px) and (max-width: 362px)  {
    .top-container .top-container-textos .texto-apresentacao {
      margin-bottom: -6%;
    }
  }

  @media screen and (min-width: 376px) and (max-width: 377px) {
    .top-container .top-container-textos .texto-apresentacao {
      margin-bottom: -6%;
    }
  }

  @media screen and (min-width: 391px) and (max-width: 394px) {
    .top-container .top-container-textos .texto-apresentacao {
      margin-bottom: -6%;
    }
  }

  @media screen and (min-width: 376px) {
    .top-container {
      padding: 0 4%;
    }
    .cards-dna-container {
      padding: 0 4%;
    }
  }

  @media screen and (min-width: 450px) {
    .top-container {
      padding: 0 8%;
    }

    .top-container-textos .texto-apresentacao {
      margin-bottom: 3%;
    }

    .cards-dna-container {
      padding: 0 8%;
    }
  }

  @media screen and (min-width: 376px) and (max-width: 390px) {
    .top-container .top-container-textos .texto-apresentacao p {
      font-size: 11.5px;
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    background-image: linear-gradient(
    kt-get($kt-state-colors, light, inverse) 35.5%,
    kt-get($kt-state-colors, light, base) 30%
  );
    padding: 20px 30px 64px;

    .top-container {
    height: 410px;
    padding: 0%;
    display: flex;
    flex-direction: row;
    max-width: 1250px;
    margin: 0 auto;

      .top-container-textos {
        position: relative;
        margin-top: 26px;
        margin-left: auto;
        width: 730px;

        .texto-apresentacao {
          width: 750px;
          margin-bottom: 30px;
          position: static;
          p {
            line-height: 150%;
            font-size: 22.2px;
          }
        }

        .frases {
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          align-items: center;
          position: static;
          p {
            float: none;
            width: fit-content;
            padding: 1.45% 2.6%;
            margin-bottom: 16px;
            font-size: 21px;
          }
        }
      }

      .top-container-img {
        position: absolute;
        .smartphone {
          width: 460px;
          max-width:none;
        }
      }
    }

    .dna-verzel {
      h2 {
        margin: 0 auto 48px;
      }

      p {
        width: fit-content;
        max-width: 990px;
        white-space: nowrap;
        letter-spacing: normal;
        margin: 0 auto 50px;
      }
    }

    .cards-dna-container {
      max-width: 1250px;
      margin: 0 auto;
      padding: 0 19px;
    }

    .botao-container .botao {
      margin: 12px auto 0;
    }
  }

  @media screen and (min-width: 1100px) {
    padding: 20px 30px 64px;

    .top-container {
      height: 450px;

      .top-container-textos {
        width: 750px;
        margin-top: 40px;
      }

      .top-container-img {
        .smartphone {
          width: 530px;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    background-image: linear-gradient(
    kt-get($kt-state-colors, light, inverse) 38.5%,
    kt-get($kt-state-colors, light, base) 30%
  );
    padding: 19px 70px 64px;

    .top-container{
      .top-container-textos{
        .texto-apresentacao{
          margin-bottom: 48px;
        }
      }
    }
  }

  @media screen and (min-width: 1275px) {
    background-image: linear-gradient(
    kt-get($kt-state-colors, light, inverse) 40%,
    kt-get($kt-state-colors, light, base) 30%
  );
    padding: 19px 90px 64px;

    .top-container {
      .top-container-img {
        .smartphone {
          width: 570px;
        }
      }
    }
  }

  @media screen and (min-width: 1340px) {
    padding: 19px 7.37vw 64px;

    .top-container {
    height: 542px;

      .top-container-textos {
        margin-top: 61px;
      }

      .top-container-img {
        position: absolute;
        .smartphone {
          width: 637px;
        }
      }
    }
  }
}
