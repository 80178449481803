#photo-container {
  width: 80px;

  > div {
    width: 100%;

    img {
      background-color: kt-get($kt-state-colors, light, base);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, sm)) {
    width: 95px;
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, md)) {
    width: 105px;
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    width: 120px;
  }
}
