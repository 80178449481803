#back-to-top {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: kt-get($kt-state-colors, homePurple, base);
  
  min-width: 30px;
  min-height: 30px;
  width: 5vw;
  height: 5vw;
  max-width: 48px;
  max-height: 48px;

  position: fixed;
  bottom: 3vw;
  right: 3vw;

  z-index: 10000;
  cursor: pointer;

  transition: all 0.3s ease-in-out;

  img {
    width: 100%;
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    bottom: 2vw;
    right: calc(3vw + 10px);
  }
}
