@import "../../../_metronic/_assets/sass/config";

.selectError{
    p{
        margin: 0;
        margin-top: 0px;
        font-size: 0.75rem;
        margin-top: 8px;
        min-height: 1em;
        text-align: left;
        font-weight: 400;
        line-height: 1em;
        color: kt-get($kt-state-colors, danger, base);
    }
}

.selectMensage{
    p{
        margin: 0;
        margin-top: 0px;
        font-size: 0.90rem;
        margin-bottom: 8px;
        min-height: 1em;
        text-align: left;
        font-weight: 400;
        line-height: 1em;
        color: kt-get($kt-state-colors, success, base);
    }
}

.selectLabel{
    p{
        margin: 0;
        margin-top: 0px;
        font-size: 0.75rem;
        min-height: 1em;
        text-align: left;
        font-weight: 400;
        line-height: 1em;
        color: kt-get($kt-state-colors, warning, base);
    }
}

.label-switch{
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    font-size: 16px;
}

.title-switch{
    display: flex;
    align-items: center;
    text-align: center;
}

.container-switch{
    width: 100%;
    max-width: 650px !important;
}

.text-butoon-switch{
  color: kt-get($kt-base-colors, grey, 5);
}

.forgot-password{
    font-size: 16px;
}
.forgot-password:hover{
    text-decoration: underline;
}

.swal-steps-jira {
  width: fit-content !important;

  p {
    margin-bottom: 20px;
    max-width: 600px;
  }
}
