// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";
@import "./_metronic/_assets/sass/variables.scss";

body {
  display: block;
  height: 100%;
  font-family: "Ubuntu", sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: "Ubuntu", sans-serif;
}

p, input, button, strong {
  font-family: "Ubuntu", sans-serif;
}

.swal2-container {
  z-index: 2147483646;
}

.red {color: #C63C3C;}
.green {color: #6AB026;}
.black {color: #333333;}
.orange {color: #FD9C26;}
.gray {color: #6D6D6D;}


// Colores Default

$WHITE: "#FFFFFF";

// Black
$BLACK: "#000000";
$BLACK_1: "#333333";

// RED
$RED: "#d93438";
$RED_1: '#C63C3C';
$RED_2: '#9C090F';

$ORANGE: '#FD9C26';

// GREEN
$GREEN: '#6AB026';
$GREEN_1: '#478F00';

// GRAY
$LIGHTGRAY: '#D3D3D3';
$LIGHTGRAY_1: '#e0e1dd';
$LIGHTGRAY_2: '#6D6D6D';
$LIGHTGRAY_3: '#e6e6e6';
$LIGHTGRAY_4: '#d1d1d1';

// BEIGE
$BEIGE: '#d18120';

$PRIMARY_4: "#00a4b2";
$PRIMARY_9: "#031d3d";

$SECONDARY_4: '#625FA4';
$SECONDARY_5: '#4E4B98';
$SECONDARY_9: '#1c0c3f';

// BLUE
$BLUE_1: '#10a9b6';
$BLUE_2: '#031d3d';


.calendar {
  .react-date-picker__wrapper {
    border-radius: 4px;
    border: 1px solid #e2e5ec;
  }

  .react-date-picker__inputGroup {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }

  .react-date-picker__inputGroup__input:invalid {
    background-color: #fff;
  }

  .react-calendar__tile--now:enabled{
    background: #F0F0F0 !important;

    &:hover, &:focus {
      background: #F0F0F0;
    }
  }

  .react-calendar__tile {
    padding: 10px 5px;
  }

  input {
    outline: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}

.kt-aside {
  width: 200px;
}
