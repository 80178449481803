#application-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  width: 100%;

  form {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .field {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 10px;

      &:first-child {
        margin-top: unset;
      }

      & > * {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        -webkit-border-radius: 0%;
        -moz-border-radius: 0%;
        border-radius: 0%;
        -webkit-focus-ring-color: none;
      }

      & > *:focus {
        &::placeholder {
          color: kt-get($kt-state-colors, homeCyan, base);
        }

        &:-ms-input-placeholder {
          color: kt-get($kt-state-colors, homeCyan, base);
        }

        &::-ms-input-placeholder {
          color: kt-get($kt-state-colors, homeCyan, base);
        }
      }

      .error-text {
        text-align: left;
        position: absolute;
        font-size: clamp(0.7rem, 1vw, 1rem);
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        top: -16%;
        padding: 0.5px 4px;
        background: kt-get($kt-state-colors, redDanger, base);
        color: kt-get($kt-state-colors, light, base);
        z-index: 4;
      }
    }

    .inputs {
      max-width: 800px;
      width: 100%;
      input {
        width: 100%;
        padding: 12px 8px;
        font-size: clamp(1rem, 1vw, 1.1rem);
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        background-color: kt-get($kt-state-colors, light, base);
        color: kt-get($kt-state-colors, homeCyan, base);
        outline: none;
        transition: all 0.4s ease;
      }

      .select2-container .select2__control {
        max-width: 100%;
        border: none;
        border-radius: 0;
        min-height: 42px;
        box-shadow: none;

        &.select2__control--is-focused {
          .select2__placeholder {
            color: kt-get($kt-state-colors, homeCyan, base);
          }
        }

        .select2__value-container,
        .select2__value-container--is-multi,
        .select2__value-container--has-value {
          max-height: 42px;
          overflow: hidden;
          flex-wrap: nowrap;
          overflow-x: scroll;
          padding: 8px;
          font-size: clamp(1rem, 1vw, 1.1rem);
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
          background-color: kt-get($kt-state-colors, light, base);
          color: kt-get($kt-state-colors, homeCyan, base);
          &::-webkit-scrollbar {
            height: 6px;
            background-color: #fff;
            border-radius: 12px;
            width: 8px;
          }
          &::-webkit-scrollbar-thumb {
            background: #eee;
            border-radius: 12px;
          }
          .select2__multi-value {
            min-width: fit-content;
            overflow: unset;
            text-overflow: unset;
          }
        }
        .select2__placeholder {
          margin: 0;
        }
      }
      .select2__menu {
        margin-top: 0px;
        border-radius: 0;
        z-index: 5;
        .select2__menu-list {
          font-size: clamp(1rem, 1vw, 1.1rem);
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
        }
      }

      .file-upload-wrapper {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: 10px auto 0;
        .error-text {
          top: -12%;
        }
        .form-group {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding: 0;
          .form-label {
            display: none;
          }
          button {
            padding: 13.8px 19.8px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            color: kt-get($kt-state-colors, light, base);
            font-family: "Montserrat", sans-serif;
            border: 1px solid kt-get($kt-state-colors, light, base);
            border-radius: 0px;
            background: transparent;
            &:hover,
            &:focus {
              background-color: kt-get($kt-state-colors, light, base);
              color: kt-get($kt-state-colors, homeCyan, base);
            }
            .flaticon-upload::before {
              margin-left: 0px;
            }
          }
          .content-wrapper {
            display: flex;
            justify-content: center;

            .image-upload-preview {
              max-width: 38px;
              height: auto;
              margin-left: 15px;
              object-fit: contain;
            }

            .selected-file-name {
              display: inline-block;
              width: 100%;
              text-align: center;
              font-size: 1rem;
              color: kt-get($kt-state-colors, light, base) !important;
              font-weight: 500;
              border: 1px dashed kt-get($kt-state-colors, light, base);
              line-height: 52.59px;
              padding: 0 5px;
              max-width: 5ch;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .submit-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .captcha-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      padding: 0;
      margin: 10px 0;

      .tooltip .tooltip-inner {
        font-family: "Montserrat", sans-serif;
      }
    }

    .submit-btn {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      min-width: 249px;
      width: 100%;
      height: 52.6px;
      max-width: 300px;
      color: kt-get($kt-state-colors, light, base);
      font-size: 1.25rem;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      padding: 10px 0px;
      border: 1px solid kt-get($kt-state-colors, light, inverse);
      background: transparent;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:hover:not(:disabled) {
        background: kt-get($kt-state-colors, light, inverse);
        color: kt-get($kt-state-colors-iniciatives, grey, base);
      }
    }
  }
  @media screen and (min-width: kt-get($kt-media-breakpoints, sm)) {
    padding: 4.44vw 0;

    .file-upload-wrapper .content-wrapper {
      .image-upload-preview {
        margin-left: 20px;
      }
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, md)) {
    form {
      .field {
        margin-top: unset;
        margin-left: 10px;
        &:first-child,
        &:nth-child(3),
        &:nth-child(5) {
          margin-left: unset;
          margin-right: 10px;
        }
        .error-text {
          top: -15%;
        }
      }

      .inputs {
        max-width: 800px;
        display: grid;
        grid-template-columns: 50% 50%;
        row-gap: 20px;

        .file-upload-wrapper {
          margin: 0;
          padding-right: 10px;
          width: 100%;
          .error-text {
            top: -5%;
          }
          .form-group {
            width: 100%;
            button {
              height: 74px;
              padding: 0 43px;
              .flaticon-upload::before {
                margin-left: 20px;
              }
            }
            .content-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: center;

              .selected-file-name {
                font-size: 1.5rem;
                line-height: unset;
                margin-left: 0 !important;
                margin-top: 18px;
                padding: 11px;
                max-width: 25ch;
              }
            }
          }
        }

        input {
          line-height: 1.4rem;
          padding: 15px 10px;
          margin-bottom: 0px;
        }

        .select2-container .select2__control {
          .select2__value-container,
          .select2__value-container--is-multi,
          .select2__value-container--has-value {
            max-height: 46px;
            padding: 10px 10px;
          }
        }
      }

      .submit-container {
        justify-content: flex-start;
        margin-left: 10px;
      }

      .captcha-container {
        margin: 0 0 15px;
      }

      .submit-btn {
        width: 304px;
        padding: 12px 0px;
      }
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    padding: 64px 0px;

    form {
      .inputs {
        row-gap: 24px;

        .field {
          margin-left: 12px;
          &:first-child,
          &:nth-child(3),
          &:nth-child(5) {
            margin-left: unset;
            margin-right: 12px;
          }
        }

        input {
          padding: 20px 12px;
        }

        .select2-container .select2__control {
          .select2__value-container,
          .select2__value-container--is-multi,
          .select2__value-container--has-value {
            max-height: 58px;
            padding: 14.5px 12px;
          }
        }

        .file-upload-wrapper {
          width: 100%;
          margin: 0;
          padding-right: 12px;
        }
        .form-group {
          button {
            .flaticon-upload::before {
              margin-left: 20px;
            }
          }
        }
        .submit-container {
          margin-left: 12px;
        }
        .captcha-container {
          margin: 0 0 15px;
        }

        .submit-btn {
          padding: 14px 0px;
        }
      }
    }
  }
  @media screen and (min-width: 1100px) {
    form .inputs {
      .file-upload-wrapper {
        padding: 0;
        grid-column: 1 / 3;
        margin: 0 auto;
        .error-text {
          top: -16%;
        }
        .form-group {
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            width: 249px;
            height: unset;
            padding: 13.8px 19.8px;
            .flaticon-upload::before {
              margin-left: 0px;
            }
          }
          .content-wrapper {
            flex-direction: row;
            .image-upload-preview {
              max-width: 80px;
              width: 38px !important;
              max-height: 55px;
              margin: 0 0 0 20px !important;
            }
            .selected-file-name {
              font-size: 1.5rem;
              line-height: unset;
              margin-left: 15px !important;
              margin-top: 0px;
              padding: 11px;
              max-width: 6ch;
            }
          }
        }
      }
      .submit-container {
        grid-column: 1 / 3;
        margin: 0;
      }
    }
  }
  @media screen and (min-width: 1380px) {
    form .inputs {
      .error-text {
        top: -20%;
        letter-spacing: -0.03rem;
      }
      .file-upload-wrapper {
        grid-column: 1 / 2;
        width: 100%;
        margin: 0;
        .error-text {
          top: -7%;
        }
        .form-group {
          display: block;
          width: 100%;
          button {
            width: 100%;
            height: 76px;
            padding: 0 50px;
          }
          .content-wrapper {
            width: 90%;
            flex-direction: column;
            .selected-file-name {
              margin-left: 0 !important;
              margin-top: 18px;
              padding: 11px;
              max-width: 23ch;
            }
          }
        }
      }
      .submit-container {
        grid-column: 2 / 3;
        margin-left: 12px;
      }
      .captcha-container {
        margin: 0 0 15px;
      }
    }
  }
  @media screen and (min-width: 1440px) {
    form {
      .inputs {
        margin-top: unset;
        .error-text {
          top: -20%;
          letter-spacing: -0.03rem;
        }
      }
    }
  }
}
