@import '../../../../../../../../../index.scss';

.collabs {
  margin-top: 10px;

  .header {
    justify-content: flex-start !important;

    .leading {
      color: $BLUE_1;
      font-size: 18px;
    }

    h5 {
      font-family: 'Montserrat Semibold', sans-serif;
      font-weight: 400;
      padding-top: 5px;
      margin-left: 5px;
    }

    .button {
      border: 0;
      padding: 0 1px;
      margin-left: 5px;
      background-color: #4E4B98;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 16px;
        color: #fff;
      }

      &:hover {
        opacity: 0.85;
      }
    }
  }
}
