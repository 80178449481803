#nosso-time-container {
  width: 100%;
  //background-color: kt-get($kt-state-colors, homePurple, base);
  background-image: url(../../../../../../app/assets/images/nosso-time-mobile.png);
  background-size: cover;
  padding: 24px 0;

  .text-content {
    h2 {
      text-align: center;
      font-family: "Montserrat", sans-serif;
      font-size: clamp(1.5em, 4vw, 3.5em);
      font-weight: 500;
      color: kt-get($kt-state-colors, light, base);
      margin-bottom: 16px;
    }

    p {
      max-width: 1022px;
      width: 90%;
      text-align: center;
      font-family: "Montserrat", sans-serif;
      font-size: clamp(1em, 2.1vw, 1.8em);
      font-weight: 400;
      letter-spacing: -0.01rem;
      color: kt-get($kt-state-colors, light, base);
      margin: 0 auto;

      span {
        color: kt-get($kt-state-colors, homeCyan, base);
      font-weight: 700;
      }
    }
  }

  .upper-staff-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 864px;
    margin: 24px auto;
    gap: 18.25px;
  }

  .staff-slider {
    max-width: 1240px;
    width: 100vw;
    margin: 0 auto;

    .swiper-container {
      width: 100%;
    }

    .swiper-slide {
      width: 80px;
      height: 80px;
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, sm)) {
    padding: 4.44vw 0;

    .text-content {
      h2 {
        margin-bottom: 3.4vw;
      }
    }

    .upper-staff-list {
      margin: 4.44vw auto;
      gap: 4vw;
    }

    .staff-slider {
      .swiper-slide {
        width: 95px;
        height: 95px;
      }
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, md)) {
    .staff-slider {
      .swiper-slide {
        width: 105px;
        height: 105px;
      }
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    background-image: url(../../../../../../app/assets/images/nosso-time-desktop.png);

    .upper-staff-list {
      display: flex;
      flex-direction: row;
      gap: 72px;
    }

    .staff-slider {
      .swiper-slide {
        width: 120px;
        height: 120px;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    padding: 64px 0;

    .text-content {
      h2 {
        margin-bottom: 48px;
      }
    }

    .upper-staff-list {
      padding: 64px auto;
    }
  }
}
