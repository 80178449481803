#dev-sistemas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 24px;
  
  .dev-sistemas_container {
    width: 94%;
    text-align: center;
    margin-bottom: 24px;

    h2 {
      font-size: clamp(1.5em, 4vw, 3.5em);
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      margin-bottom: 16px;
    }

    p {
      font-size: clamp(1em, 2.2vw, 1.85em);
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      line-height: 180%;
      letter-spacing: -0.01em;
      margin: 0 auto;

      span {
        color: kt-get($kt-state-colors, homePurple, base);
        font-weight: 700;
      }
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, sm)) {
    padding-top: 4.44vw;
    
    .dev-sistemas_container {
      margin-bottom: 4.44vw;

      h2 {
        margin-bottom: 3.3vw;
      }
    }

  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    
    .dev-sistemas_container {
      max-width: 1200px;

      p {
        max-width: 1010px;
        line-height: 150%;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    padding-top: 64px;
    
    .dev-sistemas_container {
      margin-bottom: 64px;

      h2 {
        margin-bottom: 48px;
      }
    }
  }

  .card-dev_list {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 504px;
    
    margin: 0 auto;
    box-sizing: border-box;

  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    .swiper-wrapper {
      &:hover > * {
        opacity: 0.5;
      }
      
      &:hover > *:hover {
        opacity: 1;
      }
    }
  }
  
  .swiper-wrapper {
    margin: 24px 0;
    height: 488px;
  }

  .swiper-slide {
    height: fit-content;
  }


  .swiper-button-prev, .swiper-button-next {
    &:hover {
      color: azure;
    }

    @media all and (max-width: 1024px) {
      display: none;
    }
  }
}
