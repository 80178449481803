.application-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 90%;
    height: 90%;
    background: kt-get($kt-state-colors, homeCyan, base);
    border: none;
    .lamina-container {
      display: none;
      height: 100%;
      border: 10px solid kt-get($kt-state-colors, homeCyan, base);
      img {
        height: 100%;
      }
    }
    .form-container {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
  .close-icon {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    top: 10px;
    right: 10px;
    cursor: pointer;
    .x-icon {
      color: kt-get($kt-state-colors, primary, base);
    }
  }
  @media screen and (min-height: 700px) {
    .close-icon {
      display: flex;
    }
  }
  @media screen and (min-width: 1100px) {
    .modal-content {
      justify-content: space-around;
      .lamina-container {
        display: flex;
        max-width: 50%;
      }
    }
  }
}
