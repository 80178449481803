#endereco_container {
  display: flex;
  flex-direction: column;
  width: 93%;
  align-items: center;
  margin: 16px auto;

  a {
    text-decoration: none;
    font-size: clamp(1.1em, 2.2vw, 1.85em);
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    line-height: 150%;
    text-align: center;
    margin-bottom: 8px;
  }

  p {
    font-size: clamp(1em, 1.7vw, 1.55em);
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: 150%;
    text-align: center;
    margin-bottom: 8px;

    span {
      display: none;
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, sm)) {
    margin: 3.3vw auto;

    a {
      margin-bottom: 2.3vw;
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, md)) {
    p {
      margin-bottom: 0;

      span {
        display: unset;
      }

      br {
        display: none;
      }
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    a {
      margin-bottom: 24px;
    }
  }

  @media screen and (min-width: 1440px) {
    margin: 48px auto;
  }
}
