#list-item_container {
  display: flex;
  align-items: flex-start;

  img {
    min-width: 13.90px;
    width: 2.5vw;
    max-width: 27.6px;
    margin-right: 3vw;
  }

  .content {
    h3 {
      font-size: clamp(1rem, 2.2vw, 1.85rem);
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 0.67em;
    }

    p {
      min-width: 246px;
      width: 47.1vw;
      max-width: 442.5px;
      font-size: clamp(1rem, 2.3vw, 1.8rem);
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, md)) {
    img {
      margin-right: 24px;
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    margin-bottom: max(64px);

    .content {
      p {
        max-width: 479px;
        width: 100%;
      }
    }
  }
}
