#job-card-wrapper {
  min-width: 300px;
  min-height: 433px;
  margin-top: 20px;
  width: 35vw;
  max-width: 400px;
  cursor: pointer;
  border: 1px solid #999;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;

  &:hover {
    .job-card {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.3);
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  .color-green {
    background: kt-get($kt-state-colors, homeButtonGreen, base);
  }

  .color-blue {
    background: #00a4b2;
  }

  .job-card {
    position: absolute;
    bottom: -8.5%;
    padding: 5px 0;
    left: -1px;
    width: 100.5%;

    .job-card-btn {
      appearance: none;
      width: 100%;
      font-size: clamp(0.9rem, 1.2vw, 1.3rem);
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      padding: 10px 0;
      line-height: 150%;
      height: 100%;
      color: white;
      border: 0;
      background-color: transparent;
    }
  }

  @media screen and (min-width: 805px) {
    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.3);
    }
  }
}
