#upper-staff-container {
  display: flex;
  width: min-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  .img-wrapper {
    min-width: 192px;
    width: 35vw;
    max-width: 384px;
    margin-bottom: calc(13px + 1vw);

    img {
      width: 100%;
    }
  }

  .upper-staff-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;

    h3 {
      font-size: clamp(1em, 2.2vw, 1.85em);
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      color: kt-get($kt-state-colors, light, base);
      margin: 0;
    }
    
    p {
      font-size: clamp(0.9em, 1.8vw, 1.6em);
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.05em;
      color: kt-get($kt-state-colors, light, base);
      margin-bottom: 11px;
    }

    svg {
      min-width: 18px;
      min-height: 18px;
      width: 3.5vw;
      height: 3.5vw;
      max-width: 32px;
      max-height: 32px;

      & * {
        width: 100%;
        height: 100%;
       }
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    .img-wrapper {
      margin-bottom: 24px;
    }
  }
}