//
// Aside
//

// Aside
.kt-aside {
  width: 166px !important;
  transition: kt-get($kt-aside-config, base, minimize, transition);

  @media (min-width: 1024px) {
    border-right: 1px solid #031d3d;
  }

  // Scrollbar
  @include kt-perfect-scrollbar-skin(#637099);

  // Menu
  .kt-aside-menu {
    margin: 0;
    padding: 15px 0;

    .ps__rail-y {
      right: 2px;
    }
  }
}

// Desktop Mode
@include kt-desktop {
  .aside-menu-bg {
    background: repeating-linear-gradient(
      145deg,
      #2e466a,
      #5c8d9a 65%,
      #4c799f 95%,
      #4c799f 100%
    );
  }

  ul.kt-menu__nav {
    > li {
      > a  {
       > div {
          i, span {
            color: #fff !important;
          }
        }
      }
    }
  }

  .kt-aside {
    width: 170px;
    // Menu
    .kt-aside-menu {
      background: #fff;
      .kt-menu__nav {
        > .kt-menu__item {
          margin-bottom: 0.25rem;

          > .kt-menu__link {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: auto;
            margin-bottom: 0.25rem;
            padding: 9px 5px;

            .kt-menu__link-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 15px;
              margin-bottom: 0.25rem;
            }

            .kt-menu__link-text {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-size: 1rem;
            }

            .kt-menu__link-badge {
              position: absolute;
              top: 50%;
              right: 15px;
              margin-top: -5px;
            }

            .kt-menu__ver-arrow {
              position: absolute;
              top: 50%;
              right: 25px;
            }
          }
        }
      }
    }

    // Fixed
    .kt-aside--fixed & {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: kt-get($kt-aside-config, base, fixed, zindex);
    }

    .kt-aside--static & {
      position: relative;
      z-index: 1;

      .kt-aside-menu {
        margin-top: kt-get($kt-header-config, base, desktop, default, height);
      }
    }

    .kt-header--fixed.kt-aside--static & {
      padding-top: kt-get($kt-header-config, base, default, height);
    }

    // Minimize
    .kt-aside--minimize & {
      width: kt-get($kt-aside-config, base, minimize, width);
      transition: kt-get($kt-aside-config, base, minimize, transition);
    }

    .kt-aside--minimizing & {
      overflow: hidden;

      .kt-aside-menu {
        .kt-menu__nav {
          width: kt-get($kt-aside-config, base, default, width);
          overflow: hidden;
        }
      }
    }

    // Scroll
    @include kt-perfect-scrollbar-ver-size(kt-get($kt-aside-config, base, fixed, scroll, width));
  }

  // Fixed & Minimize Aside hover
  .kt-aside--fixed.kt-aside--minimize-hover {
    .kt-aside {
      width: kt-get($kt-aside-config, base, default, width);
      transition: kt-get($kt-aside-config, base, minimize, transition);
    }

    .kt-header {
      transition: kt-get($kt-aside-config, base, minimize, transition);
      left: kt-get($kt-aside-config, base, minimize, width);
    }

    .kt-wrapper {
      transition: kt-get($kt-aside-config, base, minimize, transition);
      padding-left: kt-get($kt-aside-config, base, minimize, width);
    }
  }
}

// Build aside menu
@include kt-menu-ver-build-layout(kt-get($kt-aside-config, menu));
@include kt-menu-ver-build-skin(kt-get($kt-aside-config, menu), default);

// Aside offcanvas panel for mobile mode
@include kt-offcanvas-build(kt-aside, tablet-and-mobile, kt-get($kt-aside-config, base, offcanvas-mobile));
