.wrapper-banner {
  position: relative;

  #container-banner_principal {
    background-image: url(../../../../../../app/assets/images/img-bg-banner-principal.png);
    background-position-y: 80%;
    background-size: cover;
    height: clamp(300px, 88.88vw, 500px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      width: 87vw;
      max-width: 573px;
      margin-left: 3.5vw;
      margin-bottom: 8%;
      color: kt-get($kt-state-colors, light, base);
      font-weight: 400;
      font-size: clamp(1.2rem, 3.7vw, 2.3rem);
      font-family: "Montserrat", sans-serif;
      line-height: 154% !important;

      span {
        color: kt-get($kt-state-colors, primary, base);
        background-color: kt-get($kt-state-colors, homeButtonGreen, base);
        padding: 0px 2px;
        font-weight: 550;
      }
    }

    .banner-principal_link {
      width: 58vw;
      max-width: 435px;
      margin-left: 4vw;
      padding: 2%;
      text-align: center;
      border: 1px solid kt-get($kt-state-colors, homeButtonGreen, base);
      box-sizing: border-box;
      color: kt-get($kt-state-colors, homeButtonGreen, base);
      font-weight: 700 !important;
      font-size: clamp(0.8rem, 3.2vw, 1.8rem);
      font-family: "Montserrat", sans-serif;
      transition: 0.2s all ease;
      &:hover {
        color: kt-get($kt-state-colors, primary, base);
        background-color: kt-get($kt-state-colors, homeButtonGreen, base);
      }
    }

    .banner-principal_scroll {
      display: none;
    }

    @media screen and (min-width: 280px) and (max-width: 320px) {
      p {
        font-size: 12.7px;
      }

    }

    @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
      background-image: url(../../../../../../app/assets/images/img-bg-banner-principal-desktop.png);
      background-position-y: 60%;
      background-position-x: 90%;
      height: 100vh;
      min-height: 600px;
      padding-bottom: 96px;

      p {
        width: 60vw;
        max-width: 1025px;
        margin-left: 7.6vw;
        margin-bottom: 4.5rem;
        font-size: clamp(1.5rem, 2.8vw, 3.3rem);
        font-family: "Montserrat", sans-serif;
      }

      .banner-principal_link {
        width: 50vw;
        min-width: 530px;
        max-width: 770px;
        margin-left: 7.5vw;
        padding: 1%;
        white-space: nowrap;
        font-size: clamp(1.1rem, 1.6vw, 1.82rem);
        font-family: "Montserrat", sans-serif;

      }

      .banner-principal_scroll {
        display: flex;
        position: absolute;
        left: 50%;
        bottom: 15%;
        transform: translate(-50%);

        @media screen and (max-height: 950px) {
          bottom: 20%;
        }

        .iconeDown {
          width: 3.2vw;
          max-width: 46px;
          animation: go-back 1200ms cubic-bezier(0.4, 0, 1, 1) infinite reverse;
        }
      }
      @keyframes go-back {
        from {
          transform: translateY(20px);
          opacity: 0%;
        }
        to {
          transform: translateY(0);
        }
      }
    }
  }
}



