.fale-conosco_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  background: kt-get($kt-state-colors, homeCyan, base);

  main {
    width: 93%;

    .top {
      h2 {
        font-size: clamp(1.5rem, 4vw, 3.5rem);
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: kt-get($kt-state-colors, light, base);
        text-align: center;
        margin-bottom: 16px;
      }

      p {
        max-width: 980px;
        font-size: clamp(1em, 2.2vw, 1.85em);
        font-family: "Montserrat", sans-serif;
        letter-spacing: -0.015em;
        font-weight: 400;
        line-height: 180%;
        color: kt-get($kt-state-colors, light, base);
        text-align: center;
        margin: 0 auto;
        margin-bottom: 24px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .field {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 10px;

        &:first-child {
          margin-top: unset;
        }

        & > * {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: none;
          -webkit-border-radius: 0%;
          -moz-border-radius: 0%;
          border-radius: 0%;
          -webkit-focus-ring-color: none;
        }

        & > *:focus {
          &::placeholder {
            color: kt-get($kt-state-colors, homeCyan, base);
          }

          &:-ms-input-placeholder {
            color: kt-get($kt-state-colors, homeCyan, base);
          }

          &::-ms-input-placeholder {
            color: kt-get($kt-state-colors, homeCyan, base);
          }
        }

        span {
          text-align: left;
          position: absolute;
          font-size: clamp(0.7rem, 1.1vw, 1.08rem);
          font-family: "Montserrat", sans-serif;
          font-weight: 400;
          top: -16%;
          padding: 0.5px 4px;
          background: kt-get($kt-state-colors, redDanger, base);
          color: kt-get($kt-state-colors, light, base);
        }
      }

      .inputs {
        width: 100%;
        max-width: 752px;

        input {
          width: 100%;
          padding: 12px 8px;
          font-size: clamp(1rem, 1vw, 1.1rem);
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
          background-color: kt-get($kt-state-colors, light, base);
          color: kt-get($kt-state-colors, homeCyan, base);
          outline: none;
          transition: all 0.4s ease;
        }
      }

      .field.textarea {
        max-width: 752px;
        width: 100%;
        margin-top: 10px;

        span {
          top: -6.2%;
        }

        textarea {
          max-width: 752px;
          width: 100%;
          padding: 13px 8px;
          font-family: "Montserrat", sans-serif;
          font-size: clamp(1rem, 1vw, 1.1rem);
          font-weight: 500;
          background-color: kt-get($kt-state-colors, light, base);
          color: kt-get($kt-state-colors, homeCyan, base);
          outline: none;
          transition: all 0.4s ease;
        }
      }

      .recaptcha {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        padding: 0;
        margin: 2.5px 0 0 0;

        .tooltip .tooltip-inner {
          font-family: "Montserrat", sans-serif;
        }
      }
      

      button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        min-width: 210px;
        width: 100%;
        max-width: 335px;
        color: kt-get($kt-state-colors, light, base);
        font-size: clamp(1em, 2.2vw, 1.85em);
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        padding: 10px 0px;
        border: 1px solid kt-get($kt-state-colors, light, inverse);
        background: transparent;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        &:hover:not(:disabled) {
          background: kt-get($kt-state-colors, light, inverse);
          color: kt-get($kt-state-colors-iniciatives, grey, base);
        }
      }
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, sm)) {
    padding: 4.44vw 0;

    main {
      .top {
        h2 {
          margin-bottom: 3.3vw;
        }

        p {
          margin-bottom: 4.44vw;
        }
      }
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, md)) {
    main {
      .top {
        p {
          line-height: 150%;
        }
      }

      form {
        .field {
          margin-top: unset;
        }

        .inputs {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;

          input {
            line-height: 1.4rem;
            padding: 15px 10px;
            margin-bottom: 0px;
          }
        }

        .field.textarea {
          margin-top: 20px;

          textarea {
            padding: 15px 10px;
          }
        }

        button {
          width: calc(360px - 10vw);
          padding: 12px 0px;
        }
      }
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    padding: 64px 0px;

    main {
      form {
        .inputs {
          gap: 24px;

          input {
            padding: 20px 12px;
          }
        }

        .field.textarea {
          margin-top: 24px;

          textarea {
            padding: 20px 12px;
          }
        }

        button {
          padding: 14px 0px;
        }
      }
    }
  }

  @media screen and (min-width: 1440px) {
    main {
      .top {
        h2 {
          margin-bottom: 48px;
        }

        p {
          margin-bottom: 64px;
        }
      }

      form {
        .inputs .field {
          span {
            top: -20%;
          }
        }

        .field.textarea {
          span {
            top: -8.3%;
          }
        }
      }
    }
  }
}