#job-list-wrapper {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background: kt-get($kt-state-colors, light, base);
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid #ddd;

  .job-listing-main-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto 24px;
    .title {
      position: relative;
      margin-bottom: 10px;
      &::before {
        content: "";
        position: absolute;
        left: -15%;
        width: 90vw;
        height: 100%;
        background: kt-get($kt-state-colors, homeButtonGreen, base);
        z-index: 1;
      }
      h1 {
        position: relative;
        width: fit-content;
        padding: 10px 0;
        color: kt-get($kt-state-colors, primary, base);
        font-size: clamp(2rem, 4vw, 3.5rem);
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        margin: 0;
        z-index: 3;
      }
    }
    span {
      font-size: clamp(1rem, 2.3vw, 1.8rem);
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.01em;
      margin-bottom: 16px;

      strong {
        color: kt-get($kt-state-colors, homePurple, base);
        font-weight: 700;
      }
    }
    h3 {
      font-size: clamp(1rem, 2.3vw, 1.8rem);
      white-space: pre-line;
      max-width: 750px;
    }
    p {
      max-width: none;
    }
    #list-item_container {
      margin-bottom: 0;
    }
  }
  .jobs-card-list-content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;
  }

  .filters-wrapper {
    width: 80vw;
    margin: 0 auto;
  }

  .list-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 32px 0;
    .swiper-container {
      width: 100%;
      .swiper-wrapper {
        & > * {
          animation: cardFade 0.5s ease-in-out reverse forwards;
        }

        & > *.swiper-slide-active {
          animation: all 0.5s ease-in-out normal backwards;
        }
      }
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 330px;
    }
    .swiper-pagination {
      position: static;
    }
    .swiper-pagination-bullet {
      margin: 32px 5px 0;
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (min-width: kt-get($kt-media-breakpoints, sm)) {
    padding-top: 4.44vw;

    .job-listing-main-content {
      margin-bottom: 4.44vw;
      span {
        margin-bottom: 3.3vw;
      }
      h3 {
        white-space: normal;
      }
      p {
        width: unset;
        white-space: pre-line;
      }
    }

    .list-content {
      width: 100%;
      margin: 4.4vw 0;
    }
    .job-card-grid {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      row-gap: 5vw;
      column-gap: 40px;
      width: 90%;
      flex: 1;
      margin: 0 auto;
      & *#job-card-wrapper {
        height: 50vw;
        max-height: 573px;
      }
    }
  }
  @media screen and (min-width: 1440px) {
    padding-top: 64px;

    .job-listing-main-content {
      margin-bottom: 64px;
      span {
        margin-bottom: 48px;
      }
    }

    .list-content {
      margin: 48px 0 64px;
    }
  }
}
