//
// Layout Config
// Here you can set the demo level SASS variables and override the global variables for this demo.
// Note: To override the global variables in the demo level use the variables without "!default". More info:  https://sass-lang.com/documentation/variables
//

// State colors
$kt-state-colors: (
  // Metronic states
  brand:
    (
      base: #fff,
      inverse: #ffffff,
    ),
  light: (
    base: #ffffff,
    inverse: #f2f2f2,
  ),
  dark: (
    base: #282a3c,
    inverse: #ffffff,
  ),
  // Bootstrap states
  primary:
    (
      base: #031d3d,
      inverse: #210e3f,
    ),
  hoverPrimary: (
    base: #4e4b98,
    inverse: #ffffff,
    opacity: rgba(77, 75, 152, 0.3),
  ),
  success: (
    base: #3dff9e,
    inverse: #ffffff,
  ),
  info: (
    base: #4e4b98,
    inverse: #ffffff,
  ),
  warning: (
    base: #25cbd3,
    //#ffb822,
    inverse: #111111,
  ),
  danger: (
    base: #ff7563,
    inverse: #ffffff,
  ),
  shape: (
    base: #646c9a,
    inverse: #ffffff,
  ),
  optionSelected: (
    base: #000,
    inverse: #fff //#384c1eb4,,,,,,
  ),
  lateralAdmin: (
    base: #1c0c3f,
    inverse: #3B66A1,
  ),
  colorPortilet: (
    base: #fff ,
    inverse: #ffffff,
  ),
  redDanger: (
    base: #e60023,
    inverse: #ffffff,
  ),
  homeCyan: (
    base: #00a4b2,
    inverse: #ff5b4d,
  ),
  homeGreen: (
    base: #6ab026,
    inverse: #954fd9,
  ),
  homePurple: (
    base: #4e4b98,
    inverse: #b1b467,
  ),
  homeButtonGreen: (
    base: #ABC616,
    inverse: #5439e9,
  )

);

$kt-state-colors-iniciatives: (
  orange: (
    base: #ffc85c,
    inverse: #ffffff,
  ),
  brown: (
    base: #994834,
    inverse: #282a3c,
  ),
  blue: (
    base: #5b7eb3,
    inverse: #ffffff,
  ),
  green: (
    base: #6a992c,
    inverse: #ffffff,
  ),
  grey: (
    base: #6d6d6d,
    inverse: #ffffff,
  ),
);

// Layout
$kt-aside-default-width: 160px;
$kt-aside-minimize-width: 78px;
$kt-aside-offcanvas-width: 255px;

// Page padding
$kt-page-padding: (
  desktop: 25px,
  mobile: 15px,
);

// Page container
$kt-page-container-width: 1380px; //  fixed page width

// Content bg color
$kt-content-bg-color: #ffffff;
// Aside bg color
$kt-aside-bg-color: #ffffff;
