#cases-sucesso-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: kt-get($kt-state-colors, primary, base);

  .main-content {
    position: relative;
    max-width: 1400px;
    width: 100%;
    margin: 24px 0;

    h2 {
      text-align: center;
      color: kt-get($kt-state-colors, light, base);
      font-size: clamp(1.5rem, 4vw, 3.5rem);
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      margin-bottom: 24px;
    }

    .swiper-container {
      width: 100%;

      & * {
        overflow-y: unset;
      }
    }

    .swiper-container-3d {
      .swiper-slide-shadow-left,
      .swiper-slide-shadow-right {
        filter: opacity(0.3);
      }
    }

    .swiper-wrapper {
      & > * {
        animation: cardFade 0.5s ease-in-out reverse forwards;
      }

      & > *.swiper-slide-active {
        animation: all 0.5s ease-in-out normal backwards;
      }

      .swiper-slide {
        min-width: 199px;
        width: 35vw;
        max-width: 280px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }

    .case-spinner {
      min-height: 199px;
      height: 35vw;
      max-height: 280px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      text-align: center;
      color: kt-get($kt-state-colors, light, base);
      font-size: clamp(1em, 2.2vw, 1.85em);

      gap: 10px;

      svg {
        max-width: 90px;
        max-height: 90px;
        width: 10vw;
        height: 10vw;
      }

      .spinner-border {
        max-width: 60px;
        max-height: 60px;
        width: 6vw;
        height: 6vw;
      }
    }

    @media screen and (min-width: kt-get($kt-media-breakpoints, sm)) {
      margin: 4.44vw 0;

      h2 {
        margin-bottom: 4.44vw;
      }

      .swiper-wrapper {
        & > * {
          animation: none;
          opacity: 1;
        }
      }

      .case-spinner {
        gap: 20px;
      }
    }

    @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
      .swiper-button-next,
      .swiper-button-prev {
        display: flex;
        top: 6.5%;

        &::after {
          color: kt-get($kt-state-colors, homeButtonGreen, base);
          font-size: 3rem;
        }
      }

      .swiper-button-next {
        right: 10%;
      }

      .swiper-button-prev {
        left: 10%;
      }

      .case-spinner {
        gap: 30px;
      }
    }

    @media screen and (min-width: 1440px) {
      margin: 64px 0;

      h2 {
        margin-bottom: 64px;
      }
    }
  }
}

@keyframes cardFade {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
