#case-card-container {
  display: flex;
  flex-direction: column;
  min-width: 199px;
  width: 35vw;
  max-width: 280px;
  align-items: center;
  justify-content: center;
  background-color: kt-get($kt-state-colors, light, base);
  padding: 23px 0;

  .case-img {
    display: flex;
    min-height: 87px;
    width: 83%;
    height: 15vw;
    max-height: 120px;
    align-items: center;
    justify-content: center;
    margin-bottom: 23px;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .card-content {
    width: 84%;

    h3 {
      text-align: center;
      color: kt-get($kt-state-colors, homePurple, base);
      font-size: clamp(1rem, 2vw, 1.25rem);
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      line-height: 150%;
      margin-bottom: 17px;
    }

    p {
      text-align: center;
      color: kt-get($kt-state-colors, homePurple, base);
      font-size: clamp(1rem, 2vw, 1.25rem);
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, sm)) {
    .card-content {
      width: 80%;
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, md)) {
    padding: 3vw 0;

    .case-img {
      margin-bottom: 2.3vw;
    }

    .card-content {
      h3 {
        margin-bottom: 2.3vw;
      }
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    padding: 33px 0;

    .case-img {
      margin-bottom: 24px;
    }

    .card-content {
      h3 {
        margin-bottom: 24px;
      }
    }
  }
}
