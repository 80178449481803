#home-footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;
  background: kt-get($kt-state-colors, primary, base);

  @media (max-width: 1200px) {
    padding: 0;
  }

  .home-footer-content {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;

    ul {
      min-width: 419px;
      width: 80%;
      margin: 0rem 5rem;
      display: flex;
      justify-content: space-between;

      li {
        list-style: none;
        text-align: center;
        margin: 0 5px;

        a {
          text-align: center;
          text-decoration: none;
          font-family: "Montserrat", sans-serif;
          font-size: clamp(0.85rem, 1.7vw, 1.25rem);
          font-weight: 400;
          line-height: 120%;
          letter-spacing: 0.01rem;
          color: kt-get($kt-state-colors, light, base);
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media screen and (min-width: kt-get($kt-media-breakpoints, sm)) {
      margin: 4.8vw 0;
    }

    @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
      margin: 50px 0;
    }

    @media (max-width: kt-get($kt-media-breakpoints, lg)) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 3rem 1rem;
      gap: 30px;

      ul {
        min-width: 419px;
        margin: 1.5rem 0;
        padding: 0;
        min-height: 150px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }

  .home-footer-icons-wrapper {
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: kt-get($kt-media-breakpoints, lg)) {
      margin: 0 0.5rem;
      width: 125px;
    }
  }

  .home-footer-logo {
    padding: 0;
  }
}

.home-footer-copyright {
  background: kt-get($kt-state-colors, primary, base);
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-decoration: none;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: clamp(0.85rem, 1.7vw, 1.25rem);
    font-weight: 400;
    color: kt-get($kt-state-colors, colorPortilet, base);
  }
}
