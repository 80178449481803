#devaria-container {
  background: kt-get($kt-state-colors, primary, inverse);
  width: 100%;
  height: clamp(230px, 52vw, 484px);
  padding: clamp(2em, 6vw, 5em) 0;

  .logo-devaria {
    display: flex;

    img {
      margin: 0px auto clamp(2em, 6vw, 5em);
      width: 43.35%;
      max-width: 315px;
    }
  }

  p {
    width: clamp(300px, 27em, 600px);
    font-size: clamp(0.95em, 2.2vw, 1.85em);
    font-family: "Montserrat", sans-serif;
    font-weight: normal;
    line-height: 180%;
    color: kt-get($kt-state-colors, light, base);
    text-align: center;
    margin: 0 auto;
    letter-spacing: -0.01em;

    span {
      color: #3bd42d;
      font-weight: 700;
    }
  }

  .links {
    max-width: 750px;
    width: 88vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: clamp(2.4em, 6vw, 5em) auto 0;

    .elementos {
      &:hover {
        & span {
          color: #3bd42d;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        .icones {
          margin-right: 3%;
          svg {
            height: clamp(1.5em, 4vw, 4.4em);
            width: clamp(1.5em, 4vw, 4.4em);
            & * {
              width: 100%;
              height: 100%;
            }
          }
        }

        span {
          color: kt-get($kt-state-colors, light, base);
          align-items: center;
          font-size: clamp(0.75em, 2.2vw, 1.85em);
          font-weight: normal;
        }
      }
    }
  }

  @media screen and (max-width: 340px) {
    height: 210px;
    padding: 20px 0;

    p {
      width: 91%;
      font-size: 11px;
    }

    .links {
      margin: 20px auto 0;

      .elementos a{
        .icones {
          svg {
            height: 11px;
            width: 11px;
            & * {
              width: 100%;
              height: 100%;
            }
          }
        }

        span {
          font-size: 7.8px !important;
        }
      }
    }
  }

  @media screen and (min-width: 400px) {
    .links {
      width: 80vw;
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    height: 484px;
    padding: 64px 0;

    .logo-devaria {
      img {
        margin: 0 auto 64px;
      }
    }

    p {
      width: 864px;
      line-height: 150%;
    }

    .links {
      margin: 64px auto;
      max-width: 949px;
    }
  }
}
