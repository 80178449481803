#job-not-found-container {
  width: 100%;
  display: flex;
  margin: 50px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 830px;
  p {
    width: 90%;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: clamp(1.2rem, 2.5vw, 2.3rem);
    line-height: 150%;
    letter-spacing: -0.01em;
    color: kt-get($kt-state-colors, primary, base);
    margin: 0;
    white-space: pre-line;
    margin-bottom: 24px;
  }
  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 230px;
    width: 80%;
    img {
      min-width: 230px;
      width: 50vw;
      max-width: 450px;
    }
  }
  @media screen and (min-width: kt-get($kt-media-breakpoints, sm)) {
    p {
      margin-bottom: 4.44vw;
    }
  }
  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    margin: 100px 0;
  }
  @media screen and (min-width: 1440px) {
    p {
      margin-bottom: 64px;
    }
  }
}
