#card-dna {

  &.cyan {
    color: kt-get($kt-state-colors, homeCyan, base);
    p {
      width: clamp(256px, 23.4vw, 336px);
    }
  }


  &.green {
    color: kt-get($kt-state-colors, homeGreen, base);
    p {
      width: clamp(296px, 28vw, 402px);
    }
  }

  &.purple {
    color: kt-get($kt-state-colors, homePurple, base);
    p {
      width: clamp(310px, 28vw, 402px);
    }
  }

  &.blue {
    color: kt-get($kt-state-colors, primary, inverse);
    p {
      width: clamp(310px, 28vw, 402px);
    }
  }

  .card-text {
    display: flex;
    align-items: center;
    margin-bottom: calc(12.48px + 3.2vw);

    div {
      margin-left: 3%;

    }

    h3 {
      font-family: "Montserrat", sans-serif;
      font-size: clamp(1rem, 2.5vw, 1.85rem);
      font-weight: 700;
      line-height: 150%;
    }

    p {
      display: none;
    }

    img {
      height: clamp(48px, 11.5vw, 90px);
    }
  }

  @media (max-width: 359px) {
    h3 {
      font-size: clamp(0.7rem, 2.5vw, 0.9rem) !important;
    }

    img {
      height: 44px !important;
    }
  }

  @media screen and (min-width: 360px) and (max-width: 375px) {
    margin-left: 1% !important;
  }

  @media screen and (max-width: 575px) {
    margin-left: 4%;
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    .card-text {
      align-items: flex-start;
      margin-bottom: 52px;

      div {
        margin-left: 3.4vw;
        height: clamp(90px, 8.1vw, 122px);
      }

      h3 {
        font-size: clamp(1.2rem, 1.4vw, 1.5em);
      }

      p {
        display: flex;
        font-family: "Montserrat", sans-serif;
        font-size: clamp(1.1em, 1.3vw, 1.5em);
        font-weight: 500;
        line-height: 150%;
      }

      img {
        height: clamp(90px, 8.1vw, 122px);
      }
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1061px) {
    .card-text p {
      font-size: 13.5px;
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, xl)) {
    .card-text div {
      margin-left: 49px;
    }
  }
}
