#consult-especial_container {
  width: 93%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 24px auto;

  .top {
    max-width: 800px;
    width: 100%;
    margin: 0 auto 16px;

    h2 {
      font-size: clamp(1.5rem, 4vw, 3.5rem);
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      line-height: 100%;
      text-align: center;
      margin-bottom: 16px;
    }

    p {
      max-width: 349px;
      font-size: clamp(1rem, 2.2vw, 1.85rem);
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      letter-spacing: -0.015em;
      line-height: 180%;
      text-align: center;
      margin: 0 auto;

      span {
        color: kt-get($kt-state-colors, homePurple, base);
        font-weight: 700;
      }
    }
  }

  .img-wrapper {
    text-align: center;
    margin-bottom: 24px;

    img {
      min-width: 312px;
      width: 80%;
      max-width: 800px;
    }
  }

  .bottom {
    .list {
      & > * {
        margin-bottom: 24px;
      }
    }

    .button {
      display: flex;
      max-width: 384px;
      max-height: 64px;
      width: 100%;
      height: calc(35px + 2vw);
      align-items: center; 
      justify-content: center;
      font-size: clamp(1rem, 2vw, 1.85rem);
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      line-height: 150%;
      color: white;
      border: none;
      background: kt-get($kt-state-colors, homePurple, base);
      transition: 0.2s background-color ease;

      &:hover {
        background: kt-get($kt-state-colors, homeButtonGreen, base);
        color: unset;
      }
    }
  }
  
  @media screen and (min-width: kt-get($kt-media-breakpoints, sm)) {
    margin: 4.44vw auto;
    
    .top {
      margin: 0 auto 4.44vw;

      h2{
        margin: 0 auto 3.3vw;
      }

      p {
        max-width: 668px;
      }
    }

    .bottom {
      .list {
        & > * {
          margin-bottom: 4.44vw;
        }
      }
    }
  }

  @media screen and (min-width: kt-get($kt-media-breakpoints, lg)) {
    max-width: 1250px;
    width: 90%;
    position: relative;

    .top {
      z-index: 2;
      margin: 0 0 4.44vw;

      h2 {
        text-align: left;
      }

      p {
        text-align: left;
        margin: 0;
      }
    }

    .img-wrapper {
      text-align: right;
      position: absolute;
      right: -8%;
      bottom: 4%;
      z-index: 1;

      img {
        width: 55vw;
      }
    }

    .bottom {
      z-index: 2;

      .list {
        & > *:first-child {
          div p {
            width: 92%;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1440px) {
    margin: 64px auto;

    .top {
      margin-bottom: 64px;

      h2 {
        margin-bottom: 48px;
      }
    }

    .bottom {
      .list {
        & > * {
          margin-bottom: 64px;
        }

        & > *:first-child {
          div p {
            width: 100%;
          }
        }
      }
    }
  }
}
