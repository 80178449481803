.container-header {
  display: flex;
  padding: 16.68px 6.7%;
  justify-content: space-between;
  align-items: center;
  background: kt-get($kt-state-colors, light, base);
  height: 116px;

  .logo-header {
    width: 128px;
    @media (min-width: kt-get($kt-media-breakpoints, md)) {
      width: 209px;
    }
  }

  .header-nav-tag-link-a {
    line-height: 50px;
    white-space: nowrap;
  }

  ul {
    display: flex;
    flex: 1;
    margin: 0 1.93%;
    padding: 0;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      margin-left: 2.6%;
      height: 50px;

      a {
        color: kt-get($kt-state-colors, primary, base);
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
        text-decoration: none;
        height: 100%;

        &:hover {
          transition: color 0.2s;
          color: kt-get($kt-state-colors, hoverPrimary, base);
          text-decoration: underline;
        }
      }

      .login-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid kt-get($kt-state-colors, homeButtonGreen, base);
        background: transparent;
        padding: 5px 15px;
        cursor: pointer;
        white-space: nowrap;
        max-height: 40px;
        margin: 5px auto;

        &:hover {
          background: kt-get($kt-state-colors, homeButtonGreen, base);

          span {
            color: kt-get($kt-state-colors, light, base);
          }
        }

        span {
          display: flex;
          align-items: center;
          color: kt-get($kt-state-colors, homeButtonGreen, base);
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
          gap: 5px;
        }
      }
    }
  }

  .button-menu-header {
    width: 20px;
  }
}

.link-header-services{
  position: relative;
  
  .menu-header-services {
    position: absolute;
    top: 50px;
    left: 8px;
    z-index: 99;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    border-radius: 2px;
    box-shadow: 0 0 2px kt-get($kt-state-colors, hoverPrimary, base);
    
    min-width: 345px;
    padding: 16px;
    white-space: nowrap;
    
    background: kt-get($kt-state-colors, light, base);
    color: kt-get($kt-state-colors, hoverPrimary, base);
    
    
    &:before {
      content: "";
      width: 0; 
      height: 0; 
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 10px solid kt-get($kt-state-colors, hoverPrimary, base);

      z-index: 100;
      
      position: absolute;
      top: -12px;
      left: 16px;
    }
    a {
      border: 1px solid transparent;
      border-radius: 2px;
      padding: 8px 16px;
      line-height: normal;
      text-align: left;
      width: 100%;

      &:hover {
        transition: all 0.2s ease-in-out;
        border: 1px solid kt-get($kt-state-colors, hoverPrimary, base);
        font-weight: 500;
      }


    }
  }
}

.container-content-modal {
  height: 100%;
  background-color: kt-get($kt-state-colors, light, base);

  ul {
    padding: 0;
    border-top: 1px solid kt-get($kt-state-colors, light, inverse);
    display: flex;
    flex-direction: column;
    height: 100%;

    li {
      padding: 16px 13px;
      list-style: none;
      background: kt-get($kt-state-colors, light, base);
      border-bottom: 1px solid kt-get($kt-state-colors, light, inverse);

      &.login-container-mobile {
        background: kt-get($kt-state-colors, homeButtonGreen, base);
        
        a {
          color: kt-get($kt-state-colors, light, base);
          font-weight: 400;
        }
      }

      &.header-logout {
        margin-top: auto;
        span {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 1.2rem;
          font-weight: 500;
          color: #e60023;
        }
      }

      a {
        font-size: 12px;
        color: kt-get($kt-state-colors-iniciatives, grey, base);
        font-weight: 400;
        font-family: "Montserrat", sans-serif;

        &:hover {
          color: kt-get($kt-state-colors, primary, inverse);
          transition: color 0.2s;
        }
      }
    }
  }
}
